export interface ModelAPIIndexParams {
  _order?: string[][] | '_random';
  _page?: number;
  _limit?: number;
}

export interface ModelAPIIndexMeta {
  currentPage: number;
  lastPage: number;
  total: number;
}

export type ModelAPIIndexFieldParam =
  | string
  | number
  | boolean
  | { ne?: string | number; notIn?: (number | string)[] };

/**
 * Base utility model.
 */
export default abstract class Base<A> {
  /**
   * API plugin.
   */
  static get $api() {
    const { $api } = useNuxtApp();
    return $api;
  }

  data: A;

  constructor(data: A) {
    this.data = data;
  }

  toJSON() {
    return this.data;
  }
}
